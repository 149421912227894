import { authGuard } from '@/utils';

const Dashboard = () => import(/* webpackChunkName: "retro" */ './views/Dashboard.vue');
const CreateSession = () => import(/* webpackChunkName: "retro" */ './views/CreateSession.vue');
const EditSession = () => import(/* webpackChunkName: "retro" */ './views/EditSession.vue');
const ViewSession = () => import(/* webpackChunkName: "retro" */ './views/ViewSession.vue');
const CloneSession = () => import(/* webpackChunkName: "retro" */ './views/CloneSession.vue');

export default [
  {
    path: '/retro',
    alias: '/r',
    name: 'retro',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/retro/create',
    alias: '/r/c',
    name: 'retro-create',
    component: CreateSession,
    beforeEnter: authGuard,
  },
  {
    path: '/retro/:id/edit',
    alias: '/p/:id/e',
    name: 'retro-edit',
    props: true,
    component: EditSession,
    beforeEnter: authGuard,
  },
  {
    path: '/retro/:id',
    alias: '/r/:id',
    name: 'retro-view',
    props: true,
    component: ViewSession,
    beforeEnter: authGuard,
  },
  {
    path: '/retro/:id/clone',
    alias: '/r/:id/c',
    name: 'retro-clone',
    props: true,
    component: CloneSession,
    beforeEnter: authGuard,
  },
];
