export const KUDOS_V1 = {
  achiever: { title: 'Achiever', description: 'Lorem ipsum', icon: 'mdi-account-check', color: '#6666cc' },
  awesome: { title: 'Awesome', description: '', icon: 'mdi-account-tie', color: '#66cccc' },
  coach: { title: 'Coach', description: '', icon: 'mdi-account-supervisor', color: '#cc6699' },
  creator: { title: 'Creator', description: '', icon: 'mdi-account-question', color: '#cc6666' },
  kind: { title: 'Kind Heart', description: '', icon: 'mdi-account-heart', color: '#cc66cc' },
  leader: { title: 'Leader', description: '', icon: 'mdi-account-star', color: '#cccc66' },
  problemsolver: { title: 'Problem Solver', description: '', icon: 'mdi-account-search', color: '#cc9999' },
  teamplayer: { title: 'Team Player', description: '', icon: 'mdi-account-group', color: '#99cc99' },
  thankyou: { title: 'Thank You', description: '', icon: 'mdi-account-tie-voice', color: '#9999cc' },
};

export const KUDOS = {
  achiever: { title: 'Achiever', icon: 'mdi-account-check', color: 'cyan' },
  awesome: { title: 'Awesome', icon: 'mdi-account-tie', color: 'blue' },
  coach: { title: 'Coach', icon: 'mdi-account-supervisor', color: 'green' },
  creator: { title: 'Creator', icon: 'mdi-account-question', color: 'amber' },
  kind: { title: 'Kind Heart', icon: 'mdi-account-heart', color: 'purple' },
  leader: { title: 'Leader', icon: 'mdi-account-star', color: 'brown' },
  problemsolver: { title: 'Problem Solver', icon: 'mdi-account-search', color: 'teal' },
  teamplayer: { title: 'Team Player', icon: 'mdi-account-group', color: 'blue-grey' },
  thankyou: { title: 'Thank You', icon: 'mdi-account-tie-voice', color: 'indigo' },
};
