import { authGuard } from '@/utils';

const BacklogsList = () => import(/* webpackChunkName: "backlog" */ './views/BacklogsList.vue');
const BacklogCreate = () => import(/* webpackChunkName: "backlog" */ './views/BacklogCreate.vue');
const BacklogEdit = () => import(/* webpackChunkName: "backlog" */ './views/BacklogEdit.vue');
const BacklogView = () => import(/* webpackChunkName: "backlog" */ './views/BacklogView.vue');

export default [
  {
    path: '/backlogs',
    alias: '/bl',
    name: 'backlogs-list',
    component: BacklogsList,
    beforeEnter: authGuard,
  },
  {
    path: '/backlogs/create',
    alias: '/bl/c',
    name: 'backlog-create',
    component: BacklogCreate,
    beforeEnter: authGuard,
  },
  {
    path: '/backlogs/:id/edit',
    alias: '/bl/:id/e',
    name: 'backlog-edit',
    props: true,
    component: BacklogEdit,
    beforeEnter: authGuard,
  },
  {
    path: '/backlogs/:id',
    alias: '/bl/:id',
    name: 'backlog-view',
    props: true,
    component: BacklogView,
    beforeEnter: authGuard,
  },
];
