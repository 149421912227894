


















import { Vue, Component } from 'vue-property-decorator';
import { User, Team, Settings } from '@/models';
import Header from '@/components/Header.vue';
import Navigation from '@/components/Navigation.vue';
import Snackbar from '@/components/Snackbar.vue';

@Component({ components: { Header, Navigation, Snackbar } })
export default class DefaultLayout extends Vue {
  loading = false;
  snackbarShow = false;
  snackbarMessage = '';
  snackbarColor = 'default';
  teamDialog = true;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get team(): Team {
    return this.$store.getters['user/team'];
  }
  get settings(): Settings {
    return this.$store.getters['user/settings'];
  }

  created() {
    this.$vuetify.theme.dark = this.settings.theme.dark;
    // Event listeners
    this.$bus.$on('snackbar-notify', this.onSnackbarNotify);
    this.$bus.$on('loading-indicator', this.onLoadingIndicator);
  }

  // Event handlers
  onSnackbarNotify(message: string, color = 'default'): void {
    this.snackbarShow = true;
    this.snackbarMessage = message;
    this.snackbarColor = color;
  }
  onLoadingIndicator(state = false): void {
    this.$set(this, 'loading', state);
  }
}
