































































































import Vue from 'vue';
import { db, func } from '@/firebase';
//import SendKudo from './SendKudo.vue';
import { User, Team } from '@/models';
import { Kudo, Kudos } from '../models';
import { KUDOS } from '../defaults';
import KudosWall from '../components/KudosWall.vue';

export default Vue.extend({
  name: 'KudosView',

  components: { KudosWall },

  props: {
    tid: { type: String, required: true },
  },

  data() {
    return {
      title: 'Kudos Wall',
      description: 'Sharing team appreciation on a Kudos Wall',
      kudos: [] as Kudos,
      fab: false,
      kudoItems: KUDOS,
      kudoDialog: false,
      newKudo: {},
    };
  },

  computed: {
    user(): User {
      return this.$store.getters['user/user'];
    },
    team(): Team {
      return this.$store.getters['user/team'];
    },
  },

  watch: {
    $route() {
      this.$bind('kudos', db.collection('kudos').where('team', '==', this.$route.params.tid));
    },
  },

  created() {
    this.$bind('teamsMember', db.collection('teams').where('members', 'array-contains', this.user.email));
    //this.$bind('kudos', db.collection('kudos').where('team', '==', this.team.id));
    this.$bind('kudos', db.collection('kudos').where('team', '==', this.$route.params.tid));
    this.$bus.$on('team-changed', this.onTeamChanged);
  },

  methods: {
    createKudo(kudo: Kudo): void {
      this.newKudo = { ...kudo, from: this.user.email, team: this.team.tid };
      this.kudoDialog = true;
    },
    deleteKudo(kudo: Kudo): void {
      db.collection('kudos')
        .doc(kudo.id)
        .delete()
        .then(() => this.$bus.$emit('snackbar-notify', 'Kudo successfully deleted', 'success'))
        .catch((error) => this.$bus.$emit('snackbar-notify', error, 'error'));
    },
    sendKudo(kudo: Kudo): void {
      this.kudoDialog = false;
      kudo.date = Date.now();
      if (kudo.anonymous) delete kudo.from;
      db.collection('kudos')
        .add(kudo)
        .then(() => {
          if (kudo.email) this.sendKudoMail(kudo);
          this.$bus.$emit('snackbar-notify', 'Kudo successfully sent', 'success');
        })
        .catch((error) => this.$bus.$emit('snackbar-notify', error, 'error'));
    },
    sendKudoMail(kudo: Kudo): void {
      const sendMail = func.httpsCallable('sendMail');
      sendMail({
        dest: kudo.to,
        subject: kudo.title,
        body: kudo.description, // TODO: mane nicer content with image/icon, etc.
      }).catch((error) => this.$bus.$emit('snackbar-notify', error, 'error'));
    },
    onTeamChanged(team: Team) {
      this.$router.push(`/kudos/${team.id}`);
    },
  },
});
