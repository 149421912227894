



































import { Vue, Component, Watch } from 'vue-property-decorator';
import { db } from '@/firebase';

@Component
export default class Header extends Vue {
  title = null;
  titleLink = null;
  teams = [];

  get user() {
    return this.$store.getters['user/user'];
  }
  avatar() {
    return this.$store.getters['user/avatar'];
  }
  get team() {
    return this.$store.getters['user/team'];
  }

  @Watch('teams')
  onTeamsChanged(val) {
    if (val && val.length > 0) {
      if (!this.team) {
        this.$store.dispatch('user/setTeam', val[0]);
      }
    }
  }

  mounted() {
    if (this.user && this.user.email) {
      this.$bind('teams', db.collection('teams').where('members', 'array-contains', this.user.email));
    }
    // Listeners
    this.$bus.$on('title-change', this.onTitleChange);
  }

  navigationToggle() {
    this.$bus.$emit('navigation-toggle');
  }
  changeTeam(team) {
    this.$store.dispatch('user/setTeam', team);
  }
  login() {
    this.$router.push('login').catch(() => null);
  }
  logout() {
    this.$router.push('logout').catch(() => null);
  }
  onTitleChange(title, link = null) {
    this.title = title;
    this.titleLink = link;
  }
}
