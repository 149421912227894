export default {
  namespaced: true,

  state: {
    tiles: true,
  },

  getters: {
    tiles: (state) => state.tiles,
  },

  mutations: {
    setTiles: (state, tiles) => (state.tiles = tiles),
    toggleTiles: (state) => (state.tiles = !state.tiles),
  },

  actions: {
    setTiles: (context, tiles) => context.commit('setTiles', tiles),
    toggleTiles: (context) => context.commit('toggleTiles'),
  },
};
