






































import Vue from 'vue';
import { Kudo } from '../models';

export default Vue.extend({
  name: 'KudoCard',

  props: {
    kudo: {
      type: Object as () => Kudo,
      default: null,
    },
  },

  methods: {},
});
