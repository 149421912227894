import { authGuard } from '@/utils';

const TeamView = () => import(/* webpackChunkName: "team" */ './views/TeamView.vue');
const IcebreakersView = () => import(/* webpackChunkName: "team" */ './views/IcebreakersView.vue');

export default [
  {
    path: '/team',
    name: 'team',
    component: TeamView,
    beforeEnter: authGuard,
  },
  {
    path: '/team/icebreakers',
    name: 'icebreakers',
    component: IcebreakersView,
    beforeEnter: authGuard,
  },
];
