
































import { Vue, Component, Watch } from 'vue-property-decorator';
import { appService } from '../services';

@Component
export default class Home extends Vue {
  config = null;
  modules = [];

  @Watch('config')
  onConfigChange(config) {
    this.modules = config.modules;
  }

  mounted() {
    this.$bind('config', appService.getConfigRef())
      .then((config) => {
        if (config) this.modules = config.modules;
      })
      .catch((error) => this.$bus.$emit('snackbar-notify', error, 'error'));
    this.$bus.$emit('title-change', null);
    document.title = 'AgileMate';
    this.$analytics.logEvent('home', { user_id: this.$store.getters['user/userId'] });
  }
}
