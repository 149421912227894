import { authGuard } from '@/utils';

const Dashboard = () => import(/* webpackChunkName: "poker" */ './views/Dashboard.vue');
const CreateSession = () => import(/* webpackChunkName: "poker" */ './views/CreateSession.vue');
const EditSession = () => import(/* webpackChunkName: "poker" */ './views/EditSession.vue');
const ViewSession = () => import(/* webpackChunkName: "poker" */ './views/ViewSession.vue');

export default [
  {
    path: '/poker',
    alias: '/p',
    name: 'poker',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/poker/create',
    alias: '/p/c',
    name: 'poker-create',
    component: CreateSession,
    beforeEnter: authGuard,
  },
  {
    path: '/poker/:id/edit',
    alias: '/p/:id/e',
    name: 'poker-edit',
    props: true,
    component: EditSession,
    beforeEnter: authGuard,
  },
  {
    path: '/poker/:id',
    alias: '/p/:id',
    name: 'poker-view',
    props: true,
    component: ViewSession,
    beforeEnter: authGuard,
  },
];
