//import Kudos from './Kudos.vue';
import KudosView from './views/KudosView.vue';

export default [
  {
    path: '/kudos',
    name: 'kudos',
    component: KudosView,
    meta: { roles: ['auth'] },
    children: [
      {
        path: '/kudos/:tid',
        name: 'kudos-view',
        props: true,
        component: KudosView,
      },
    ],
  },
];
