import firebase, { firestore } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';

const config = {
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

export const auth = app.auth();

export const db = app.firestore();
db.settings({ experimentalForceLongPolling: true }); // enable long polling
db.enablePersistence({ synchronizeTabs: true }).catch(() => null); // enable offline persistence

export const func = app.functions('europe-west1');

if (process.env.NODE_ENV === 'local') {
  func.useFunctionsEmulator('http://localhost:5001');
}

export const analytics = firebase.analytics(app);
//analytics.setAnalyticsCollectionEnabled(true);

// Get current user as promise
export const user = () => {
  return new Promise<firebase.User | null>((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      //console.log('Firebase user resolved', user);
      if (user) {
        analytics.setUserId(user.uid);
        //console.log('[Analytics] Setting user id', user);
        //analytics.setUserProperties({ level: user.claims.level }); // See https://fireship.io/lessons/firebase-analytics-web-guide/
      }
      resolve(user);
    }, reject);
  });
};

export const $firebase = firebase;

export { firebase, firestore };

export default { app, auth, db, func, firestore, analytics, $firebase, user };
