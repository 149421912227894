import Vue from 'vue';
import { analytics, app, auth, db } from '@/firebase';

const firebase = {
  install(Vue) {
    //Vue.prototype.$firebase = $firebase;
    //Vue.prototype.$fireAnalytics = analytics;
    Vue.prototype.$analytics = analytics;
    Vue.prototype.$app = app;
    Vue.prototype.$auth = auth;
    Vue.prototype.$db = db;
  },
};

Vue.use(firebase);

export default firebase;
