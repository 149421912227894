import Vue from 'vue';

const bus = {
  install(Vue) {
    Vue.prototype.$bus = new Vue();
  },
};

Vue.use(bus);

export default bus;
