import { defaultSettings } from './defaults';

export default {
  namespaced: true,

  state: {
    settings: defaultSettings,
  },

  getters: {
    settings: (state) => state.settings,
    countdown: (state) => state.settings.countdown,
    coffeeCountdown: (state) => state.settings.coffeeCountdown,
    sound: (state) => state.settings.sound,
    participants: (state) => state.settings.participants,
  },

  mutations: {
    setSettings: (state, settings) => {
      state.settings = settings;
    },
    setCountdown: (state, countdown) => {
      state.settings.countdown = countdown;
    },
    setCoffeeCountdown: (state, countdown) => {
      state.settings.coffeeCountdown = countdown;
    },
    setSound: (state, sound) => {
      state.settings.sound = sound;
    },
    setParticipants: (state, participants) => {
      state.settings.participants = participants;
    },
  },

  actions: {
    setSettings: (context, settings) => {
      context.commit('setSettings', settings);
    },
    setCountdown: (context, countdown) => {
      context.commit('setCountdown', countdown);
    },
    setCoffeeCountdown: (context, countdown) => {
      context.commit('setCoffeeCountdown', countdown);
    },
    setSound: (context, sound) => {
      context.commit('setSound', sound);
    },
    setParticipants: (context, participants) => {
      context.commit('setParticipants', participants);
    },
  },
};
