
























import Vue from 'vue';
import TeamSelector from '@/components/TeamSelector.vue';
import KudoCard from './KudoCard.vue';
import { Kudos } from '../models';

export default Vue.extend({
  name: 'KudosWall',

  components: { TeamSelector, KudoCard },

  props: {
    kudos: {
      type: Array as () => Kudos,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  methods: {},
});
