











import { Vue, Component } from 'vue-property-decorator';

const defaultColor = 'default';
const defaultTimeout = 3000;

@Component
export default class Snackbar extends Vue {
  show = false;
  message = '';
  color = defaultColor;
  timeout = defaultTimeout;

  created() {
    this.$bus.$on('snackbar-notify', this.onSnackbarNotify);
  }

  onSnackbarNotify(message: string, color = defaultColor, timeout = defaultTimeout): void {
    this.show = true;
    this.message = message;
    this.color = color;
    this.timeout = timeout;
  }
}
