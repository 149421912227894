import { auth, db, $firebase } from '@/firebase';
import { User, FirebaseUser } from '@/models';

let firebaseUser = auth.currentUser;

export const userService = {
  user: null as User | null,
  usersRef: db.collection('users'),
  teamsRef: db.collection('teams'),

  isAuthenticated() {
    return this.user !== null;
  },
  getUser() {
    return this.user;
  },
  getUserId() {
    return this.user?.id;
  },
  getAvatar() {
    return this.user?.avatar;
  },
  getName() {
    return this.user?.username;
  },
  getEmail() {
    return this.user?.email;
  },
  setName(username: string) {
    return this.updateProfile({ displayName: username });
  },
  setAvatar(avatar: string) {
    return this.updateProfile({ photoURL: avatar });
  },
  async updateProfile(profile: { displayName?: string | null | undefined; photoURL?: string | null | undefined }) {
    await firebaseUser?.updateProfile(profile);
    firebaseUser = auth.currentUser;
    this.user = this.convertUser(firebaseUser);
  },
  async getLoginMethods(email: string) {
    return await auth.fetchSignInMethodsForEmail(email);
  },
  async isExistingUser(email: string) {
    const loginMethods = await this.getLoginMethods(email);
    return loginMethods && loginMethods.length > 0;
  },
  // Login & logout
  loginAnonymously() {
    return auth.signInAnonymously();
  },
  loginWithEmail(email: string, password: string) {
    return auth.signInWithEmailAndPassword(email, password);
  },
  loginWithGoogle() {
    const provider = new $firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(provider);
  },
  logout() {
    return auth.signOut();
  },
  createUserWithEmailAndPassword(username: string, password: string) {
    return auth.createUserWithEmailAndPassword(username, password);
  },
  // Users collection
  addUserToCollection() {
    if (!this.user) return;
    return this.usersRef.doc(this.user.id).set(this.user);
  },
  async getUserFromCollection(email: string) {
    const snapshot = await this.usersRef.where('email', '==', email).limit(1).get();
    if (snapshot.empty) {
      return null;
    } else {
      return snapshot.docs[0].data() as User;
    }
  },
  // Helpers
  convertUser(firebaseUser: FirebaseUser | null): User | null {
    firebaseUser = auth.currentUser;
    if (!firebaseUser) return null;
    return {
      id: firebaseUser.uid,
      username: firebaseUser.displayName,
      avatar: `https://robohash.org/${firebaseUser.uid}.png`, //firebaseUser.photoURL,
      email: firebaseUser.email,
      lastLogon: firebaseUser.metadata['b'] ? Number(firebaseUser.metadata['b']) : null,
    } as User;
  },
};

auth.onAuthStateChanged((user: FirebaseUser | null) => {
  firebaseUser = user;
  userService.user = userService.convertUser(user);
  //console.log('[user.service] User state changed', firebaseUser, userService.user);
});
