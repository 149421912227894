import { authGuard } from '@/utils';

const QuizDashboardView = () => import(/* webpackChunkName: "quiz" */ './views/QuizDashboardView.vue');
const QuizCreateView = () => import(/* webpackChunkName: "quiz" */ './views/QuizCreateView.vue');
const QuizEditView = () => import(/* webpackChunkName: "quiz" */ './views/QuizEditView.vue');
//const QuizTakeView = () => import(/* webpackChunkName: "quiz" */ './views/_QuizTakeView.vue');
const QuizPreviewView = () => import(/* webpackChunkName: "quiz" */ './views/QuizPreviewView.vue');
const QuizCloneView = () => import(/* webpackChunkName: "quiz" */ './views/QuizCloneView.vue');
//const QuizAdminView = () => import(/* webpackChunkName: "quiz" */ './views/_QuizAdminView.vue');

const QuizInfoView = () => import(/* webpackChunkName: "quiz" */ './views/QuizInfoView.vue');
//const QuizLobbyView = () => import(/* webpackChunkName: "quiz" */ './views/_QuizLobbyView.vue');
const QuizGameView = () => import(/* webpackChunkName: "quiz" */ './views/QuizGameView.vue');
const AdminQuizGameView = () => import(/* webpackChunkName: "quiz" */ './views/AdminQuizGameView.vue');

export default [
  {
    path: '/quiz',
    alias: '/q',
    name: 'quiz-dashboard',
    component: QuizDashboardView,
    beforeEnter: authGuard,
  },
  /*
  {
    path: '/quiz/admin',
    alias: '/q/a',
    name: 'quiz-admin',
    component: QuizAdminView,
  },
  */
  {
    path: '/quiz/create',
    alias: '/q/c',
    name: 'quiz-create',
    component: QuizCreateView,
    beforeEnter: authGuard,
  },
  {
    path: '/quiz/:id',
    alias: '/q/:id',
    name: 'quiz-view',
    props: true,
    component: QuizInfoView,
    beforeEnter: authGuard,
  },
  {
    path: '/quiz/:id/edit',
    alias: '/q/:id/e',
    name: 'quiz-edit',
    props: true,
    component: QuizEditView,
    beforeEnter: authGuard,
  },
  {
    path: '/quiz/:id/preview',
    alias: '/q/:id/p',
    name: 'quiz-preview',
    props: true,
    component: QuizPreviewView,
  },
  {
    path: '/quiz/:id/clone',
    alias: '/q/:id/c',
    name: 'quiz-clone',
    props: true,
    component: QuizCloneView,
    beforeEnter: authGuard,
  },
  {
    path: '/quiz/:id/admin/:gid',
    alias: '/q/:id/a/:gid',
    name: 'quiz-game-admin',
    props: true,
    component: AdminQuizGameView,
    beforeEnter: authGuard,
  },
  /*
  {
    path: '/quiz/:id/lobby/:gid',
    alias: '/q/:id/l/:gid',
    name: 'quiz-lobby',
    props: true,
    component: QuizLobbyView,
  },
  */
  {
    path: '/quiz/:id/game/:gid',
    alias: '/q/:id/g/:gid',
    name: 'quiz-game',
    props: true,
    //meta: { layout: 'simple-layout', title: 'Quiz' },
    component: QuizGameView,
    beforeEnter: authGuard,
  },
];
