
















import { Vue, Component } from 'vue-property-decorator';
import { Team } from '@/models';
import { userService } from '../modules/user/services';

@Component
export default class TeamSelector extends Vue {
  user = this.$store.getters['user/user'];
  team = this.$store.getters['user/team'];
  teams = [];

  created() {
    this.$bind('teams', userService.teamsRef.where('members', 'array-contains', this.user.email));
  }

  changeTeam(team: Team): void {
    //console.log('Team ID', team.id, team);
    //team.tid = team.id;
    this.$store.dispatch('user/setTeam', team);
    this.$bus.$emit('team-changed', team);
  }
}
