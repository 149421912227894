import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import Home from '@/views/Home.vue';
import { routes as UserRoutes } from '@/modules/user';
import { routes as BacklogsRoutes } from '@/modules/backlogs';
import { routes as BoardsRoutes } from '@/modules/boards';
import { routes as PokerRoutes } from '@/modules/poker';
import { routes as TimerRoutes } from '@/modules/timer';
import { routes as KudosRoutes } from '@/modules/kudos';
import { routes as SurveyRoutes } from '@/modules/surveys';
import { routes as RetroRoutes } from '@/modules/retro';
import { routes as QuizRoutes } from '@/modules/quiz';
import { routes as TeamRoutes } from '@/modules/team';

Vue.use(VueRouter);

const routes = [
  ...UserRoutes,
  ...BacklogsRoutes,
  ...BoardsRoutes,
  ...PokerRoutes,
  ...TimerRoutes,
  ...KudosRoutes,
  ...SurveyRoutes,
  ...RetroRoutes,
  ...QuizRoutes,
  ...TeamRoutes,
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'AgileMate';
  next();
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
