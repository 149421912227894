import { user } from '@/firebase';
import { authGuard } from '@/utils';

const Login = () => import(/* webpackChunkName: "user" */ './views/Login.vue');
const Logout = () => import(/* webpackChunkName: "user" */ './views/Logout.vue');
const Profile = () => import(/* webpackChunkName: "user" */ './views/Profile.vue');

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: checkAuthForward,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    //beforeEnter: authGuard,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    //beforeEnter: authGuard,
  },
];

// eslint-disable-next-line
async function checkAuthForward(to, from, next) {
  const currentUser = await user();
  //console.log('[router] user.checkAuthForward', auth, to, from, user);
  if (currentUser) {
    const redirectFrom = (to.query['redirectFrom'] as string) || 'home';
    //console.log('[login]', 'User already authenticated, redirecting...', redirectFrom);
    next(redirectFrom);
  } else {
    next();
  }
}
