import { authGuard } from '@/utils';

const BoardsList = () => import(/* webpackChunkName: "boards" */ './views/BoardsList.vue');
const BoardCreate = () => import(/* webpackChunkName: "boards" */ './views/BoardCreate.vue');
const BoardEdit = () => import(/* webpackChunkName: "boards" */ './views/BoardEdit.vue');
const BoardView = () => import(/* webpackChunkName: "boards" */ './views/BoardView.vue');

export default [
  {
    path: '/boards',
    alias: '/b',
    name: 'boards-list',
    component: BoardsList,
    beforeEnter: authGuard,
  },
  {
    path: '/boards/create',
    alias: '/b/c',
    name: 'board-create',
    component: BoardCreate,
    beforeEnter: authGuard,
  },
  {
    path: '/boards/:id/edit',
    alias: '/b/:id/e',
    name: 'board-edit',
    props: true,
    component: BoardEdit,
    beforeEnter: authGuard,
  },
  {
    path: '/boards/:id',
    alias: '/b/:id',
    name: 'board-view',
    props: true,
    component: BoardView,
    beforeEnter: authGuard,
  },
];
