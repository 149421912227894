






import { Vue, Component } from 'vue-property-decorator';
import { appService } from './services';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import SimpleLayout from '@/layouts/SimpleLayout.vue';

@Component({ components: { DefaultLayout, SimpleLayout } })
export default class App extends Vue {
  get theme(): string {
    return this.$vuetify.theme.dark ? 'dark' : 'light';
  }
  get layout(): void {
    return this.$route.meta.layout || 'default-layout';
  }
  get connected(): boolean {
    return this.$store.getters['user/connected'];
  }

  created() {
    window.addEventListener('online', this.onStatusUpdate, false);
    window.addEventListener('offline', this.onStatusUpdate, false);
    this.$store.dispatch('user/authAction');
    document.title = 'AgileMate';
  }

  mounted() {
    this.$bind('config', appService.getConfigRef())
      .then((config) => {
        if (config) {
          /* eslint-disable @typescript-eslint/no-unused-vars */
          this.$store.dispatch(
            'user/setNavigation',
            config.modules.map(({ description, features, ...item }) => item) // Remove description and features fields from store
          );
          //this.$bus.$emit('navigation-change', this.navItems);
        }
      })
      .catch((error) => this.$bus.$emit('snackbar-notify', error, 'error'));
  }

  beforeDestroy() {
    window.removeEventListener('online', this.onStatusUpdate, false);
    window.removeEventListener('offline', this.onStatusUpdate, false);
  }

  onStatusUpdate(e) {
    const { type } = e;
    const online = type === 'online';
    if (online) {
      this.$bus.$emit('snackbar-notify', 'Connection re-established', 'success');
      this.$bus.$emit('network-connect');
    } else {
      this.$bus.$emit('snackbar-notify', 'Connection has been lost', 'error');
      this.$bus.$emit('network-disconnect');
    }
    this.$store.dispatch('user/setConnected', online);
  }
}
